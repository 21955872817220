import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import './styles.css';
import './choices.min.css';
import { MainPage } from './pages/MainPage';
import { LoginPage } from './pages/LoginPage';
import { AdminLayout } from './components/adminLayout';
import { ProductPage } from './pages/ProductPage';
import { RepairRequestPage } from './pages/RepairRequestPage';
import { ServicesPage } from './pages/ServicesPage';
import { RequestsPage } from './pages/RequestsPage';
import { OrderNotifyPage } from './pages/OrderNotifyPage';

export const useRoutes = (isAuthenticated) => {
    const data = JSON.parse(localStorage.getItem('userData'));
    if (data === null ? isAuthenticated : !!data.token) {
      return (
        <AdminLayout>
          <Routes>
            <Route path='/products/:page' element={<ProductPage />} />
            <Route path='/services' element={<ServicesPage />} />
            <Route path='/repair' element={<RepairRequestPage />} />
            <Route path='/requests/:page' element={<RequestsPage />} />
            <Route path='/notifications' element={<OrderNotifyPage />} />
            <Route path='*' element={<Navigate to='/services' replace />} />
          </Routes>
        </AdminLayout>
      )
    }
    return (
      <Layout>
        <Routes>
          <Route index element={<MainPage />} />
          <Route path='/admin' element={<LoginPage />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </Layout>
    );
}