import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { NameContext } from '../../context/NameContext'

export const RequestsPage = () => {
    const name = useContext(NameContext);
    const { page } = useParams();
    const [requests, setRequests] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = useState('');
    const [id, setId] = useState(0);
    const pageSize = 15;
    const data = JSON.parse(localStorage.getItem('userData'));
    useEffect(()=>{
        name.setName('Заявки');
        fetch(`/api/orders/page?page=${page}&pageSize=${pageSize}`, {
            headers: {
                'Authorization': "Bearer " + (data.token)
            }
        })
        .then(res => res.json())
        .then(res => setRequests(res))
    },[name])

    
    function pagination() {
        let arr = [];
        for(let i = 1; i <= requests?.pageModel?.totalPages; i ++) {
            let d = {num: `${i}`, link: `/requests/${i}`}
            arr.push(d)
        }
        return arr;
    }
    function RequestsModal(props) {
        const [form, setForm] = useState({Name: '', Phone: '', Email: ''});
        const changeHandler = (event) => {
            setForm({ ...form, [event.target.name]: event.target.value });
        };

        function changeRequest() {
            return fetch('/api/orders/' + id, {
                method: 'PUT',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => {
                if(res.status === 200) res.json()
                else console.log(res.status);
            })
            .then(setModalShow(false))
        }

        useEffect(()=>{
            if(type=== 'changeService')
            fetch('/api/orders/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(res => setForm({Name: res.name, Phone: res.phone, Email: res.email }))
        },[])
        
        return (
            <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Изменить запрос
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                    <label htmlFor='Name'>Имя</label>
                    <input 
                    name="Name" 
                    id="Name"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Name}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Phone'>Телефон</label>
                    <input 
                    name="Phone" 
                    id="Phone"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Phone}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Email'>Почта</label>
                    <input 
                    name="Email" 
                    id="Email"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Email}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { changeRequest() }}>Сохранить</button>
              </Modal.Body>
            </Modal>
        );
    }

    function deleteRequest (id) {
        return fetch('/api/orders/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(setModalShow(false))
        .then(window.location.reload())
    }

    return (
        <div style={{paddingLeft: '300px'}}>
            <div className='cont'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Имя</th>
                            <th>Телефон</th>
                            <th>Почта</th>
                            <th>Поломка</th>
                            <th>Колесо</th>
                        </tr>
                        {requests?.orders?.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.name}</td>
                                <td>{x.phone}</td>
                                <td>{x.email}</td>
                                <td>{x.malfunction.text}</td>
                                <td>{x.wheel.brand.name + ' ' + x.wheel.model}</td>
                                <td><button onClick={() => { setId(x.id); setType('changeService'); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteRequest(x.id) }} className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>
            </div>
            <div className="col-12 pagination-block">
                {!requests?.pageModel?.hasPreviousPage && !requests?.pageModel?.hasNextPage ? '' : 
                    <>
                        {requests?.pageModel?.hasPreviousPage ? <a href={"/requests/" + (parseInt(page) - 1)}>«</a> : ''}
                        {pagination()?.map((x) => {
                            return <a key={x.num} className={page === x.num ? 'current' : ''} href={x.link}>{x.num}</a>
                        })}   
                        {requests?.pageModel?.hasNextPage ? <a href={"/requests/" + (parseInt(page) + 1)}>»</a> : ''}      
                    </> 
                }
            </div>
            <RequestsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                
        </div>
    )
}