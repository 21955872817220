import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { NameContext } from '../../context/NameContext'

export const OrderNotifyPage = () => {
    const name = useContext(NameContext);
    const [notify, setNotify] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [id, setId] = useState(0);
    const [type, setType] = useState('');
    const data = JSON.parse(localStorage.getItem('userData'));

    useEffect(()=>{
        name.setName('Почты для уведомлений');
        fetch('/api/ordernotifyemails', {
            headers: {
                'Authorization': "Bearer " + (data.token)
            }
        })
            .then(res => res.json())
            .then(items => setNotify(items));
    },[name])

    function OrderNotifyModal(props) {
        const [form, setForm] = useState({Address: ''});
        const changeHandler = (event) => {
            setForm({ ...form, [event.target.name]: event.target.value });
        };

        function changeNotify() {
            return fetch('/api/ordernotifyemails/' + id, {
                method: 'PUT',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => {
                if(res.status === 200) res.json()
                else console.log(res.status);
            })
            .then(setModalShow(false))
            .then(window.location.reload())
        }

        function addNotify() {
            return fetch('/api/ordernotifyemails/', {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(setModalShow(false))
            .then(window.location.reload())
        }

        useEffect(()=>{
            if (type === 'changeNotify') {
                fetch('/api/ordernotifyemails/' + id, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.token}`
                    }
                }).then(res => res.json())
                .then(res => setForm({Address: res.address}))
            }
        },[])
        
        return (
            <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {type === 'addNotify' ? 'Добавить почту' : 'Изменить почту'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                {type === 'addNotify' ? 
                
                <>
                    <label htmlFor='Address'>Почта</label>
                    <input 
                    name="Address" 
                    id="Address"
                    className='model_input'
                    onChange={changeHandler}                
                    type="email"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { addNotify() }}>Сохранить</button>
                </> 
                
                : 
                
                <>
                    <label htmlFor='Address'>Почта</label>
                    <input 
                    name="Address" 
                    id="Address"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Address}
                    type="email"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { changeNotify() }}>Сохранить</button>
                </> 
                }
                
              </Modal.Body>
            </Modal>
        );
    }

    function deleteNotify(id) {
        return fetch('/api/ordernotifyemails/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(setModalShow(false))
        .then(window.location.reload())
    }

    return (
        <div style={{paddingLeft: '300px'}}>
            <div className='cont'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Почта</th>
                        </tr>
                        {notify.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.address}</td>
                                <td><button onClick={() => { setType('changeNotify'); setId(x.id); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteNotify(x.id) }} className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>
                <div style={{display: 'flex'}}>
                    <button onClick={() => { setType('addNotify'); setModalShow(true) }} className="services_button button_mid_w">Добавить</button>
                </div>
            </div>
            <OrderNotifyModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                
        </div>
    )
}