import React from 'react'
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/main/logo.svg';
import { AuthContext } from '../../context/AuthContext';
import { AdminNavbar } from '../AdminNavbar';
import './admLayout.css';

export const AdminLayout = ({children}) => {
    const auth = useContext(AuthContext);

    return (
        <div className="layout">
            <div className="overlay">
                <div className="drawer">
                    <img className="adm_logo" src={logo} alt='Логотип UMK' />
                    <ul className="navMenu">
                        <li>
                            <Link to="/services" className="link">Поломки и причины</Link>
                        </li>
                        <li>
                            <Link to="/products/1" className="link">Товары</Link>
                        </li>
                        <li>
                            <Link to="/" className="link link_disabled">ЧЗВ</Link>
                        </li>
                        <li>
                            <Link to="/repair" className="link">Ремонт</Link>
                        </li>
                        <li>
                            <Link to="/requests/1" className="link">Заявки</Link>
                        </li>
                        <li>
                            <Link to="/notifications" className='link'>Почта для заявок</Link>
                        </li>
                    </ul>
                    <div className="exitButton" onClick={() => auth.logout()}>Выйти</div>
                </div>
            </div>
            <AdminNavbar />
            {children}
        </div>
    )
}