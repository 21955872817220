import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { NameContext } from '../../context/NameContext'

export const ServicesPage = () => {
    const name = useContext(NameContext);
    const [services, setServices] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [id, setId] = useState(0);
    const [type, setType] = useState('');
    const data = JSON.parse(localStorage.getItem('userData'));

    useEffect(()=>{
        name.setName('Поломки и их причины');
        fetch('/api/services')
            .then(res => res.json())
            .then(items => setServices(items));
    },[name])

    function ServicesModal(props) {
        const [form, setForm] = useState({Title: '', Description: '', Time: '', CustomPrice: 'От  ₽'});
        const changeHandler = (event) => {
            setForm({ ...form, [event.target.name]: event.target.value });
        };

        function changeService() {
            return fetch('/api/services/' + id, {
                method: 'PUT',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => {
                if(res.status === 200) res.json()
                else console.log(res.status);
            })
            .then(setModalShow(false))
        }

        function addService() {
            return fetch('/api/services/', {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(setModalShow(false))
        }

        useEffect(()=>{
            if (type === 'changeService') {
                fetch('/api/services/' + id, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.token}`
                    }
                }).then(res => res.json())
                .then(res => setForm({Title: res.title, Description: res.description, Time: res.time, CustomPrice: res.customPrice }))
            }
        },[])
        
        return (
            <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {type === 'addService' ? 'Добавить поломку' : 'Изменить поломку'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                {type === 'addService' ? 
                
                <>
                    <label htmlFor='Title'>Поломка</label>
                    <input 
                    name="Title" 
                    id="Title"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Description'>Описание</label>
                    <input 
                    name="Description" 
                    id="Description"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Time'>Время</label>
                    <input 
                    name="Time" 
                    id="Time"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='customPrice'>Цена</label>
                    <input 
                    id="customPrice"
                    name="CustomPrice" 
                    className='model_input'
                    onChange={changeHandler}
                    value={form.CustomPrice}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { addService() }}>Сохранить</button>
                </> 
                
                : 
                
                <>
                    <label htmlFor='Title'>Поломка</label>
                    <input 
                    name="Title" 
                    id="Title"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Title}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Description'>Описание</label>
                    <input 
                    name="Description" 
                    id="Description"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Description}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Time'>Время</label>
                    <input 
                    name="Time" 
                    id="Time"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Time}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='CustomPrice'>Цена</label>
                    <input 
                    id="CustomPrice"
                    name="CustomPrice" 
                    className='model_input'
                    onChange={changeHandler}
                    value={form.CustomPrice}       
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { changeService() }}>Сохранить</button>
                </> 
                }
                
              </Modal.Body>
            </Modal>
        );
    }

    function deleteService(id) {
        return fetch('/api/services/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(setModalShow(false))
    }

    return (
        <div style={{paddingLeft: '300px'}}>
            <div className='cont'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Поломка</th>
                            <th>Описание</th>
                            <th>Время</th>
                            <th>Цена</th>
                        </tr>
                        {services.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.title}</td>
                                <td>{x.description}</td>
                                <td>{x.time}</td>
                                <td>{x.customPrice}</td>
                                <td><button onClick={() => { setType('changeService'); setId(x.id); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteService(x.id) }} className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>
                <div style={{display: 'flex'}}>
                    <button onClick={() => { setType('addService'); setModalShow(true) }} className="services_button button_mid_w">Добавить</button>
                </div>
            </div>
            <ServicesModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                
        </div>
    )
}