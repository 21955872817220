import React, { useState, useEffect, useContext} from 'react';
import { NameContext } from '../../context/NameContext';
import Modal from 'react-bootstrap/Modal';

export const RepairRequestPage = () => {
    let context = useContext(NameContext);
    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = useState('');
    const [id, setId] = useState(0);
    const [whMalfs1, setWhMalfs1] = useState([]); 
    const [whModels1, setWhModels1] = useState([]);
    const data = JSON.parse(localStorage.getItem('userData'));

    useEffect(()=> {
        context.setName('Ремонт');
        fetch('/api/ordermalfunctions')
            .then(res => res.json())
            .then(items => setWhMalfs1(items));
        fetch('/api/wheels')
            .then(res => res.json())
            .then(items => setWhModels1(items));
        return;
    }, [context])
    let options = [];
    for(let elem of whModels1) {
        options.push({value: elem.id, label:elem.brand.name +' '+ elem.model});
    }
    let opts1 = [];
    for(let elem of whMalfs1) {
        opts1.push({value: elem.id, label: elem.text})
    }

    function RepairModal(props) {
        const [form, setForm] = useState({BrandName: '', Model: '', Power: '', MaxSpeed: '', PowerReserve: ''});
        const [text, setText] = useState({Text: ''});
        const textHandler = (event) => {
            setText({Text: event.target.value});
        };
        const changeHandler = (event) => {
            setForm({ ...form, [event.target.name]: event.target.value });
        };
        function addMalfunction() {
            return fetch('/api/ordermalfunctions/', {
                method: 'POST',
                body: JSON.stringify(text),
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(window.location.reload())
        }
        function changeMalfunction() {
            return fetch('/api/ordermalfunctions/' + id, {
                method: 'PUT',
                body: JSON.stringify(text),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(window.location.reload())
        }
        function changeModel() {
            return fetch('/api/wheels/' + id, {
                method: 'PUT',
                body: JSON.stringify(form),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(window.location.reload())
        }
        function addModel() {
            return fetch('/api/wheels/', {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(window.location.reload())
        }
        
        useEffect(()=>{
            if (type === "changeMalfunction") {
                fetch('/api/ordermalfunctions/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(res => setText({Text: res.text}))
        } else if (type === "changeModel") {
            fetch('/api/wheels/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                }
            }).then(res => res.json())
            .then(res => setForm({BrandName: res.brand.name, Model: res.model, Power: res.power, MaxSpeed: res.maxSpeed, PowerReserve: res.powerReserve}))
        }
        },[])
            
        return (
            <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {type === 'addModel' ? 'Добавить модель' : type === 'addMalfunction' ? 'Добавить неисправность колеса' :
                   type === 'changeMalfunction' ? 'Изменить неисправность' : 'Изменить модель'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                {type === 'addModel' ?  
                <>
                    <label htmlFor='BrandName'>Название бренда</label>
                    <input 
                    name="BrandName" 
                    id="BrandName"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Model'>Модель</label>
                    <input 
                    name="Model" 
                    id="Model"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Power'>Мощность, Вт</label>
                    <input 
                    name="Power" 
                    id="Power"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='MaxSpeed'>Максимальная скорость, км/ч</label>
                    <input 
                    id="MaxSpeed"
                    name="MaxSpeed" 
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='PowerReserve'>Запас мощности</label>
                    <input 
                    name="PowerReserve"
                    id="PowerReserve" 
                    className='model_input'
                    onChange={changeHandler}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => addModel()}>Сохранить</button>
                </>

                : 
                type === 'addMalfunction' ? 

                <>
                    <label htmlFor='malf_text'>Неисправность</label>
                    <input 
                    name="malf_text" 
                    id="malf_text"
                    className='model_input'
                    onChange={textHandler}
                    required
                    placeholder='Текст'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px'}} onClick={() => {addMalfunction()}}>Сохранить</button>
                </>  
                
                : 
                type === 'changeMalfunction' ? 
            
                <>
                    <label htmlFor='malf_text'>Неисправность</label>
                    <input 
                    name="malf_text" 
                    id="malf_text"
                    className='model_input'
                    value={text.Text}
                    onChange={textHandler}
                    required
                    placeholder='Текст'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px'}} onClick={() => { changeMalfunction() }}>Сохранить</button>

                </>
                
                : 
                
                <>
                                        <label htmlFor='BrandName'>Название бренда</label>
                    <input 
                    name="BrandName" 
                    id="BrandName"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.BrandName}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Model'>Модель</label>
                    <input 
                    name="Model" 
                    id="Model"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Model}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Power'>Мощность, Вт</label>
                    <input 
                    name="Power" 
                    id="Power"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Power}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='MaxSpeed'>Максимальная скорость, км/ч</label>
                    <input 
                    id="MaxSpeed"
                    name="MaxSpeed" 
                    className='model_input'
                    onChange={changeHandler}
                    value={form.MaxSpeed}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='PowerReserve'>Запас мощности</label>
                    <input 
                    name="PowerReserve"
                    id="PowerReserve" 
                    className='model_input'
                    onChange={changeHandler}
                    value={form.PowerReserve}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => changeModel()}>Сохранить</button>

                </>
                
                }
              </Modal.Body>
            </Modal>
        )
    }
    
    function deleteModel(id) {
        return fetch('/api/wheels/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(window.location.reload())
    }

    function deleteMalfunction(id) {
        return fetch('/api/ordermalfunctions/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(window.location.reload())
    }
    
    return (
        <div style={{display: 'flex', paddingLeft: '300px'}}>
            <div className='split_cont'>
                <h2 className='split_name'>Модель колес</h2>
                <table className='table'>
                    <tbody>
                    <tr>
                        <th>Id</th>
                        <th>Бренд</th>
                        <th>Модель</th>
                    </tr>
                    {whModels1.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.brand.name}</td>
                                <td>{x.model}</td>
                                <td><button onClick={() => { setType('changeModel'); setId(x.id); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteModel(x.id) }}className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>

                <button onClick={() => { setType('addModel'); setModalShow(true); }} className="services_button button_full_w">Добавить</button>
            </div>
            <div className='split_cont'>
                <h2 className='split_name'>Неисправности колес</h2>
                <table className='table'>
                    <tbody>
                    <tr>
                        <th>Id</th>
                        <th>Текст</th>
                    </tr>
                    {whMalfs1.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.text}</td>
                                <td><button onClick={()=>{ setType('changeMalfunction'); setId(x.id); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteMalfunction(x.id) }}className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>
                <button onClick={() => {  setType('addMalfunction'); setModalShow(true);}} className="services_button button_full_w">Добавить</button>
            </div>
            <RepairModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
        </div>
    )
}