import { YMaps, Map } from "react-yandex-maps";

export const ContactsCont = () => {

    return (
        <div className="container">
            <div className="contacts">
                <div className="contacts__info">
                    <div className="contacts__title title">Контакты</div>
                    <div className="contacts__net">
                        <a className="contacts__net_phone" href="tel:+79829617576"> +7 (982) 961-75-76</a>
                        <a className="contacts__net_mail" href="mailto:UMK.help@mail.ru">UMK.help@mail.ru</a>
                    </div>
                    <ul className="contacts__address">
                        <li className="address__list" style={{margin: '0 0 10px 0'}}>г. Екатеринбург, Ленина, 10</li>
                        <li className="address__list">г. Екатеринбург, Бажова, 37</li>
                    </ul>
                    <div className="contacts__work-time">Пн-Пт: 9:00 – 18:00</div>
                    <div className="contacts__messengers">
                        <a className="messengers__link" href="#"><div className="messengers__item messengers__telegram"></div></a>
                        <a className="messengers__link" href="#"><div className="messengers__item messengers__vk"></div></a>
                        <a className="messengers__link" href="#"><div className="messengers__item messengers__instagram"></div></a>
                    </div>
                </div>
                <div className="contacts__map" id='contacts__map'>
                    <YMaps>
                        <div>
                            <Map width='100%' height={455} defaultState={{ center: [56.83, 60.60], zoom: 13 }} />
                        </div>
                    </YMaps>
                </div>
            </div>
        </div>
    )
}