import React from 'react'

export const AboutUsCont = () => {
    return(
        <div className="container">
            <div id="aboutUs" className="about" style={{padding: '60px 0 0 0'}}>
                <div className="about__us">
                    <div className="us__title title">О нас</div>
                    <div className="us__body">
                        <p>УМК&nbsp;— признанный лидер в&nbsp;сфере обслуживания, ремонта и&nbsp;модификации моноколес.</p>
                        <p>Каждый сотрудник является профи в своем деле.</p>
                        <p>Обслуживание моноколес, ремонт корпусов, ремонт и&nbsp;сборка аккумуляторных батарей с&nbsp;нуля&nbsp;— наш&nbsp;профиль.</p>
                    </div>
                    <a className="us__contact yellow-button" href="#request">Связаться с нами</a>
                </div>
                <div className="about__numbers">
                    <div className="numbers__item numbers__first"></div>
                    <div className="numbers__item numbers__second"></div>
                    <div className="numbers__item numbers__third"></div>
                    <div className="numbers__item numbers__four"></div>
                </div>
            </div>
        </div>
    )
}