export const FAQCont = () => {

    function Spoiler(id){    
        let item = document.getElementById("askSpoiler" + id);
        let element = document.getElementById("contentSpoiler" + id); 
        item.classList.toggle('arrow-visible');
        element.classList.toggle('spoiler-visible');   
    };

    return (
        <div className="container">
            <div id="questions" className="questions">
                <div className="questions__title title">Часто задаваемые вопросы</div>
                    <div className="questions__body">
                        <div id="askSpoiler1" className="questions__item" onClick={()=>Spoiler(1)}>
                            <div className="item__title">С моим колесом что-то не так, но почему — не знаю, как мне быть?</div>
                            <div id="linkSpoiler1" className="item__arrow"></div>
                        </div>
                    <div id="contentSpoiler1" className="item__spoiler">Привозите ваше моноколесо к нам на диагностику. В кратчайшие сроки выясним в чем причина проблемы и предложим вам методы ее решения под ключ :)</div>
                        
                        <div id="askSpoiler2" className="questions__item" onClick={()=>Spoiler(2)}>
                            <div className="item__title">Сколько будут длиться сервисные работы?</div>
                            <div id="linkSpoiler2" className="item__arrow"></div>
                        </div>
                    <div id="contentSpoiler2" className="item__spoiler">Срок проведения сервисных работ зависит от характера неисправности. Типовые проблемы решаем быстро, но экзотика, или "приключения" могут сдвинуть сроки.</div>
                        
                        <div id="askSpoiler3" className="questions__item" onClick={()=>Spoiler(3)}>
                            <div className="item__title">Что такое приключения и непредвиденные обстоятельства?</div>
                            <div id="linkSpoiler3" className="item__arrow"></div>
                        </div>
                    <div id="contentSpoiler3" className="item__spoiler">Приключениями и непредвиденными обстоятельствами мы называем ситуации, когда после разборки моноколеса возникают трудности, или обнаруживаются дополнительные проблемы. Увы, такое нередко бывает.</div>
                        
                        <div id="askSpoiler4" className="questions__item" onClick={()=>Spoiler(4)}>
                            <div className="item__title">Что если ремонт моего колеса окажется сложнее, чем казалось ранее?</div>
                            <div id="linkSpoiler4" className="item__arrow"></div>
                        </div>
                        <div id="contentSpoiler4" className="item__spoiler">При&nbsp;возникновении форс-мажора, мы&nbsp;заранее, перед&nbsp;началом выполнения соответствующих работ предупреждаем заказчика и&nbsp;согласовываем список операций, необходимых для&nbsp;приведения моноколеса в&nbsp;рабочее состояние. Заранее оглашаем вилку цен&nbsp;и&nbsp;сроки. Если&nbsp;заказчик принимает решение не&nbsp;проводить дополнительные работы&nbsp;— могут быть решены заявленные ранее проблемы, или&nbsp;вовсе моноколесо может быть возвращено заказчику без&nbsp;проведения каких-либо работ, но&nbsp;в&nbsp;таком случае будет необходимо заплатить за&nbsp;диагностику.</div>
                        
                        <div id="askSpoiler5" className="questions__item" onClick={()=>Spoiler(5)}>
                            <div className="item__title">Как с вами связаться?</div>
                            <div id="linkSpoiler5" className="item__arrow"></div>
                        </div>
                    <div id="contentSpoiler5" className="item__spoiler">Вы можете оставить заявку по форме внизу, либо позвонить по контактному номеру.<br />
                        Важно! Встретиться с вами мы сможем только по предварительной договоренности.</div>
                    </div>
            </div>
        </div>
    )
}