import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { NameContext } from '../../context/NameContext'

export const ProductPage = () => {
    const name = useContext(NameContext)
    const [products, setProducts] = useState([])
    const { page } = useParams();
    const [type, setType] = useState('');
    const [id, setId] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const data = JSON.parse(localStorage.getItem('userData'));
    const pageSize = 10;
    let img;

    useEffect(()=>{
        name.setName('Товары');
        fetch(`/api/products/page?page=${page}&pageSize=${pageSize}`)
            .then(res => res.json())
            .then(items => setProducts(items));
    },[name])
    
    function pagination() {
        let arr = [];
        for(let i = 1; i <= products?.pageModel?.totalPages; i ++) {
            let d = {num: `${i}`, link: `/products/${i}`}
            arr.push(d)
        }
        return arr;
    }

    function ProductsModal(props) {
        const formData = new FormData();
        const [form, setForm] = useState({Name: '', Price: '', OuterUrl: ''});
        
        const changeHandler = (event) => {
            setForm({ ...form, [event.target.name]: event.target.value });
        };
        
        const addCover = (event) => {
            img = event.target.files[0];
        }
        function changeProduct() {
            formData.append('Name', form.Name);
            formData.append('Price', form.Price);
            formData.append('OuterUrl', form.OuterUrl);
            formData.append('Cover', img);
            return fetch('/api/products/' + id, {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': "Bearer "+ (data.token)
                }
                })
            .then(res => {
                if(res.status === 200) res.json()
                else console.log(res.status);
            })
            .then(setModalShow(false))
            .then(window.location.reload())
        }

        function addProduct() {
            formData.append('Name', form.Name);
            formData.append('Price', form.Price);
            formData.append('OuterUrl', form.OuterUrl);
            formData.append('Cover', img);
            return fetch('/api/products', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': "Bearer "+(data.token)
                    }
            }).then(res => res.json())
            .then(setModalShow(false))
        }

        useEffect(()=>{
            if (type === 'changeProduct') {
                fetch('/api/products/' + id, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${data.token}`
                    }
                }).then(res => res.json())
                .then(res => {
                    setForm({Name: res.name, Price: res.price, OuterUrl: res.outerUrl })
                })
            }
        },[])
        
        return (
            <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {type === 'addProduct' ? 'Добавить товар' : 'Изменить товар'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                {type === 'addProduct' ? 
                
                <>
                    <label htmlFor='Name'>Название</label>
                    <input 
                    name="Name" 
                    id="Name"
                    className='model_input'
                    onChange={changeHandler}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Cover'>Фото</label>
                    <input type='file' name="Cover" id="Cover" style={{marginBottom: '8px'}} onChange={addCover}/>
                    
                    <label htmlFor='Price'>Цена</label>
                    <input 
                    name="Price" 
                    id="Price"
                    className='model_input'
                    onChange={changeHandler}                
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='OuterUrl'>Ссылка</label>
                    <input 
                    id="OuterUrl"
                    name="OuterUrl" 
                    className='model_input'
                    onChange={changeHandler}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { addProduct() }}>Сохранить</button>
                </> 
                
                : 
                
                <>
                    <label htmlFor='Name'>Название</label>
                    <input 
                    name="Name" 
                    id="Name"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Name}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='Cover'>Фото</label>
                    <input type='file' name="Cover" id="Cover" style={{marginBottom: '8px'}} onChange={addCover}/>
                    
                    <label htmlFor='Price'>Цена</label>
                    <input 
                    name="Price" 
                    id="Price"
                    className='model_input'
                    onChange={changeHandler}
                    value={form.Price}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <label htmlFor='OuterUrl'>Ссылка</label>
                    <input 
                    id="OuterUrl"
                    name="OuterUrl" 
                    className='model_input'
                    onChange={changeHandler}
                    value={form.OuterUrl}
                    type="text"
                    required
                    placeholder='Текст...'
                    />
                    <button className='services_button' style={{backgroundColor:'#2f80ed', width: '200px', marginTop: '16px'}} onClick={() => { changeProduct() }}>Сохранить</button>
                </> 
                }
                
              </Modal.Body>
            </Modal>
        );
    }

    function deleteProduct(id) {
        return fetch('/api/products/' + id, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        }).then(res => res.json())
        .then(setModalShow(false))
        .then(window.location.reload())
    }
    return (
        <div style={{paddingLeft: '300px'}}>
            <div className='cont'>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Название</th>
                            <th>Фото</th>
                            <th>Цена</th>
                            <th>Ссылка</th>
                            <th>Рейтинг</th>
                        </tr>
                        {products?.products?.map((x)=> {
                        return <tr key={x.id}>
                                <td>{x.id}</td>
                                <td>{x.name}</td>
                                <td><img src={x.coverUrl} width='150' height='150' alt='' /></td>
                                <td>{x.price}</td>
                                <td><a className='product_link' href={x.outerUrl}>aliexpress.com</a></td>
                                <td>{x.rating}</td>
                                <td><button onClick={() => { setType('changeProduct'); setId(x.id); setModalShow(true); }} className='services_button change_button'>Изменить</button></td>
                                <td><button onClick={() => { deleteProduct(x.id) }} className='services_button delete_button'>Удалить</button></td>
                            </tr>
                    })}
                    </tbody>
                </table>
                <div style={{display: 'flex'}}>
                    <button onClick={() => { setType('addProduct'); setModalShow(true) }} className="services_button button_mid_w">Добавить</button>
                </div>
            </div>
            <div className="col-12 pagination-block">
                {!products?.pageModel?.hasPreviousPage && !products?.pageModel?.hasNextPage ? '' :
                    <>
                        {products.pageModel.hasPreviousPage ? <a href={"/products/" + (parseInt(page) - 1)}>«</a> : ''}
                        {pagination()?.map((x) => {
                            return <a key={x.num} className={page === x.num ? 'current' : ''} href={x.link}>{x.num}</a>
                        })}        
                        {products?.pageModel?.hasNextPage ? <a href={"/products/" + (parseInt(page) + 1)}>»</a> : ''}
                    </>
                }
            </div>
            <ProductsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
                
        </div>
    )
}