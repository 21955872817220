import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook';
import './login.css';

export const LoginPage = () => {
    const auth = useContext(AuthContext); 
    const { loading, request } = useHttp();
    const [form, setForm] = useState({UserName: '', Password: ''});

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const loginHandler = async () => {
        try {
            const data = await request("/api/auth/login", "POST", { ...form });
            auth.login(data.token, data.refreshToken);
        } catch (error) {
        }
    };
    return (
            <div className="request_forma request_forma__login">
                <div className="rf_top flex justify-content-between">
                    <p className="rft_title">Вход</p>
                </div>

            <div className="request_form rf_p3">
        
            <span className="text-danger field-validation-valid"></span>
            <div className="rf_p1">
                <div className="rf_line flex justify-content-between">
                    <div className="rf_block rfb_w2">
                        <input 
                        className="rf_input" 
                        type="text" 
                        required
                        placeholder="Логин" 
                        id="Login" 
                        name="UserName"
                        autoFocus
                        onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className="rf_line flex justify-content-between">  
                    <div className="rf_block rfb_w2">
                        <input 
                            className="rf_input" 
                            required 
                            placeholder="Пароль"
                            type="password" 
                            id="Password" 
                            name="Password"
                            onChange={changeHandler}
                        />
                    </div>
                </div>
            </div>

            <div className="rf_p2 rf_p4">
                {/*<div className="rf_final_check">
                    <div className="p_check1">
                        <input type="checkbox" id="RememberMe" name="RememberMe" value="true"/>
                        <label htmlFor="RememberMe">Запомнить меня?</label>
                    </div>
                </div>*/}
                <button className="rf_btn rf_btn_m1" onClick={loginHandler}>Войти</button>
            </div>
            </div>
    </div>
    )
}