import { createContext } from "react";

function noop() { }

export const AuthContext = createContext({
    token: null,
    refreshToken: null,
    isAuthenticated: false,
    login: noop,
    logout: noop
})