import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { NameContext } from './context/NameContext';
import { useAuth } from './hooks/auth.hook';
import { useName } from './hooks/name.hook';
import { useRoutes } from './routes';

export const App = () => {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    const { login, logout, token, refreshToken } = useAuth();
    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated);
    let { name, setName } = useName(); 
    return (
      <BrowserRouter basename={baseUrl}>
        <AuthContext.Provider value={{ login, logout, token, refreshToken, isAuthenticated }}>
          <NameContext.Provider value={{ name, setName }}>
            { routes }
          </NameContext.Provider>
        </AuthContext.Provider>
      </BrowserRouter>
    );
  }