import { useState, useCallback, useEffect } from "react";

const storageName = 'userData'
export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    const login = useCallback((jwtToken, refresh) => {
        setToken(jwtToken);
        setRefreshToken(refresh);

        localStorage.setItem(storageName, JSON.stringify({
            token: jwtToken,
            refreshToken: refresh,
        }))
    }, [])

    const logout = useCallback(() => {
        setToken(null);
        setRefreshToken(null);
        localStorage.removeItem(storageName);
    }, []);
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName));
        if (data && data.token && data.refreshToken) {
            login(data.token, data.refreshToken);
        };
    }, [login]);

    return { login, logout, token, refreshToken };
}