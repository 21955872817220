import React, { useEffect, useRef, useState } from 'react';
import logo from '../../images/main/logo.svg';
import bg1 from '../../images/main/main-background.jpg';
import bg2 from '../../images/main/main-background2.jpg';

export const Header = () => {
    const box1 = useRef(null);
    const box2 = useRef(null);
    const bg = useRef(null);
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    }
    
    let timerId = setInterval(MainSlider, 10000);
    let flag = true;
    let background;
    let leftBox;
    let rightBox;
    
    useEffect(() => {
        background = bg.current;
        leftBox = box1.current;
        rightBox = box2.current;
    }, [])
    function ColorLeft(){
        if(flag){
            MainSlider();
            clearInterval(timerId);
            timerId = setInterval(MainSlider, 10000);
        }
    }
    
    function ColorRight(){
        if(!flag){
            MainSlider();
            clearInterval(timerId);
            timerId = setInterval(MainSlider, 10000);
        }
    }
    
    
    function MainSlider(){
        if(flag){
            leftBox.style.background = "#fff";
            rightBox.style.background = "rgba(255, 255, 255, 0.36)";
            background.style.backgroundImage = `url(${bg1})`;
            flag = false;
        }
            
        else{
            leftBox.style.background = "rgba(255, 255, 255, 0.36)";
            rightBox.style.background = "#fff";
            background.style.backgroundImage = `url(${bg2})`;
            flag = true;
        }
    }

    return (
        <div id="bg" className="main-content" ref={bg} style={{background: `url(${bg1})`}}>
            <div className="background">
                <nav className="main-content__header navbar navbar-expand-lg">
                    <div id="navbar" className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img className="main-content__logo" src={logo} alt=""/>
                        </a>
                        <div id="nav-mobile" className="nav-mobile">
                            <a className="navbar-phone" href="#">+7 999 999 99-99</a>
                            <div id="nav-toggler" className={isActive ? "burger-toggler navbar-toggler navbar-toggler-on" : "burger-toggler navbar-toggler"} onClick={() => toggleClass()}></div>
                            <div id="burger-menu" className="burger-menu erase" style={isActive ? { display: 'block'}: {display : 'none'}}>
                                <a id="burger-service" className="burger-item links-item_active info-link-margin burger-service" href="#crashes">Сервис</a>
                                {/*<a id="burger-shop" className="burger-item info-link-margin burger-shop" href="#shop">Наш магазин</a>*/}
                                <a id="burger-asks" className="burger-item info-link-margin burger-asks" href="#questions">Новичку</a>
                                <a id="burger-about" className="burger-item info-link-margin burger-about" href="#aboutUs" style={{margin: '0'}}>О нас</a>
                            </div>
                        </div>

                        <div id="navbarNavAltMarkup" className="collapse navbar-collapse">
                        <div id="navbar-nav-first" className="navbar-nav navbar-nav-first" style={{flexBasis: '45%'}}>
                            <a id="navbar-city" className="nav-link" href="#">Екатеринбург</a>
                            <a id="navbar-phone" className="nav-link link-phone" href="#">+7 999 999 99-99</a>
                        </div>
                        <div id="navbar-nav-second" className="navbar-nav navbar-nav-second" style={{flexBasis: 'auto'}}>
                            <a id="burger-service" className="nav-link links-item_active info-link-margin" href="#crashes">Сервис</a>
                            {/*<a id="burger-shop" className="nav-link info-link-margin" href="#shop">Наш магазин</a>*/}
                            <a id="burger-asks" className="nav-link info-link-margin" href="#questions">Новичку</a>
                            <a id="burger-about" className="nav-link info-link-margin" href="#aboutUs" style={{margin: '0'}}>О нас</a>
                        </div>
                        </div>
                    </div>
                </nav>

                <div className="main-content__title">Ремонт и модификация моноколес</div>
                <a href="#crashes" className="main-content__catalogue yellow-button">К каталогу услуг</a>
                <div className="main-content__slider">
                    <div className="slider__box_active" id="box1" ref={box1} onClick={() => ColorLeft()} style={{background: 'rgb(255, 255, 255)'}}></div>
                    <div className="slider__box" id="box2" ref={box2} onClick={() => ColorRight()} style={{background: 'rgba(255, 255, 255, 0.36)'}}></div>
                </div>
            </div>
        </div>
    )
}