import React from 'react'
import { AboutUsCont } from '../../components/AboutUsCont'
import { ContactsCont } from '../../components/ContactsCont'
import { FAQCont } from '../../components/FAQCont'
import { FormCont } from '../../components/FormCont'
import { Header } from '../../components/HeaderCont'
import { RepairCont } from '../../components/RepairCont'
import { StoreCont } from '../../components/StoreCont'

export const MainPage = () => {
    return (
        <>
            <Header />
            <RepairCont />
            { /* <StoreCont /> */ }
            <AboutUsCont />
            <FAQCont />
            <ContactsCont />
            <FormCont />
        </>
    )
}