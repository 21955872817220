import React from 'react';
import { useContext } from 'react';
import { NameContext } from '../../context/NameContext';
import './admNavbar.css';

export const AdminNavbar = () => {
    const context = useContext(NameContext);
    return (
        <div className="adm_navbar">
            <div className='navbar_name'>
                {context.name}
            </div>
        </div>
    )
}