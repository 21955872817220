import logo from '../../images/main/logo.svg';

export const Footer = () => {
    return (
        <div className="container-fluid footer-form" style={{boxShadow: '0 -5px 5px -5px rgba(0, 0, 0, .3)'}}>
                <div className="footer">
                    <div className="footer-grid">
                        <div className="footer-grid__box-links">
                            <div className="footer__links">
                                <a href="#"><div className="footer__links_link">Сервис</div></a>
                                {/*<a href="#"><div className="footer__links_link">Наш магазин</div></a>*/} {/*Когда появится магазин изменить стиль .burger_about на top: 50% (сейчас 11% ) */}
                                <a href="#"><div className="footer__links_link">Новичку</div></a>
                                <a href="#"><div className="footer__links_link">О нас</div></a>
                            </div>
                        </div>
                        <div className="footer-grid__box-addresses">
                            <div className="footer__addresses">
                                <div className="footer__addresses_phone"><a href="tel:+79829617576"></a>+7 (982) 961-75-76</div>
                                <div className="footer__addresses_address">г. Екатеринбург, Ленина, 10</div>
                                <div className="footer__addresses_address">г. Екатеринбург, Бажова, 37</div>
                            </div>
                        </div>
                        <div className="footer-grid__box-button">
                            <a href="#request" style={{flexShrink: '0'}}>
                                <div className="footer__fix-request yellow-button">Оставить заявку на ремонт</div>
                            </a>
                        </div>
                        <div className="footer-grid__box-contacts">
                            <div className="footer__contacts">
                                <div className="footer__contacts_logo">
                                    <a href="#"><img className="main-content__logo" src={logo} alt=""/></a>
                                </div>
                                <div className="footer__contacts_text">Связь с нами:</div>
                                <div className="footer__contacts_messengers contacts__messengers">
                                    <a className="messengers__link" href="#"><div className="messengers__item messengers__telegram"></div></a>
                                    <a className="messengers__link" href="#"><div className="messengers__item messengers__vk"></div></a>
                                    <a className="messengers__link" href="#"><div className="messengers__item messengers__instagram"></div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    )
}