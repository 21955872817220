import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const FormCont = () => {
    const [whMalfs, setWhMalfs] = useState([]); 
    const [whModels, setWhModels] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [form, setForm] = useState({Name: '', Phone: '', Email: ''})
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };
    const changeWheelId = (event) => {
        setForm({...form, WheelId : event.value});
    }
    const changeMalfunctionId = (event) => {
        setForm({...form, MalfunctionId: event.value});
    }
    useEffect(()=> {
        fetch('/api/ordermalfunctions')
            .then(res => res.json())
            .then(items => setWhMalfs(items))
        fetch('/api/wheels')
            .then(res => res.json())
            .then(items => setWhModels(items))
    }, [])
    let options = [];
    for(let elem of whModels) {
        options.push({value: elem.id, label:elem.brand.name +' '+ elem.model});
    }
    let opts1 = [];
    for(let elem of whMalfs) {
        opts1.push({value: elem.id, label: elem.text})
    }
    const styles = {
        control: () => ({
            display: 'flex',
            borderRadius: '8px'
        }),
        singleValue: () => ({
            color: 'hsl(0, 0%, 20%)',
            gridArea: '1/1/2/3',
            marginLeft: '2px',
            marginRight: '2px',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            paddingBottom: '4px',
            paddingTop: '4px'
        })
    }

    function handleCheckbox() {
        setIsSubscribed(current => !current);
    }

    function validate() {
        if(validateName() && validatePhone() && validateMail() && isSubscribed && form.WheelId !== '' && form.MalfunctionId !== '') addRequest()
        else alert('Заполните все поля')
    }
    
    function validateName(){
        let name = form.Name;
        if(name == "") {
            alert("Укажите ваше имя");
            return false;
        }
        if(/\d/.test(name)){
            alert("Имя не должно содержать цифры");
            return false;
        }
        else
            return true;
    }
    
    function validatePhone() {
        let phone = form.Phone;
        if(phone == "") {
            alert("Укажите ваш телефон");
            return false;
        }
        if(phone.length < 11 || phone.length > 11){
            alert("Телефон должен состоять из 11 символов")
            return false;
        }
        if(/[a-zа-яё]/i.test(phone)){
            alert("Телефон должен содержать только цифры")
            return false;
        }
        else
            return true;
    }
    
    function validateMail() {
        let mail = form.Email;
        if(mail == "") {
            alert("Укажите ваш e-mail");
            return false;
        }
        if(!mail.includes('@') || !mail.includes('.')){
            alert("Введен некорректный e-mail");
            return false;
        }
        else
            return true;
    }
    function addRequest() {
        return fetch('/api/orders', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }
    return (
        <div className="container-fluid request-form" style={{backgroundColor: '#FFBC00'}}>
            <div className="request">
                <div id="request" className="request__title title">Оставьте заявку на ремонт</div>
                <div className="request__body">
                    <div className="body__title subtitle">Расскажите о вашем моноколесе и оставьте свои данные, чтобы мы могли с вами связаться :)</div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6" style={{margin: '0 0 15px 0'}}>
                                <div className="body__form-item">
                                    <div className="form-item__title">Модель колеса</div>
                                    <div className="form-item__form" >
                                        <div className="form_wrapper">
                                            <Select 
                                                options={options}
                                                styles={styles}
                                                onChange={changeWheelId}
                                                placeholder=''
                                                theme={(theme) => ({
                                                    ...theme,
                                                    border: '2px solid #FFBC00',
                                                    padding: '4px',
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#FFBC00',
                                                        primary25: '#FFBC00',
                                                        primary50: '#FFBC00',
                                                        primary75: '#FFBC00'
                                                    },
                                                })}
                                             />
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-6" style={{margin: '0 0 40px 0'}}>
                                <div className="body__form-item">
                                    <div className="form-item__title">Неисправность</div>
                                    <div className="form-item__form">
                                        <div className="form_wrapper">
                                            <Select 
                                                id="MalfunctionId"
                                                name="MalfunctionId"
                                                onChange={changeMalfunctionId}
                                                options={opts1}
                                                styles={styles}
                                                placeholder=''
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#FFBC00',
                                                        primary25: '#FFBC00',
                                                        primary50: '#FFBC00',
                                                        primary75: '#FFBC00'
                                                    },
                                                })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-3" style={{margin: '0 0 15px 0'}}>
                                <div className="body__form-item">
                                    <div className="form-item__title">Имя*</div>
                                    <div className="form-item__form">
                                        <input 
                                            className="form-item__select input-text" 
                                            type="text" 
                                            name="Name"
                                            id="Name" 
                                            onChange={changeHandler}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3" style={{margin: '0 0 15px 0'}}>
                                <div className="body__form-item">
                                    <div className="form-item__title">Телефон*</div>
                                    <div className="form-item__form">
                                        <input 
                                            className="form-item__select input-text" 
                                            type="tel" 
                                            name="Phone"
                                            id="Phone" 
                                            onChange={changeHandler}
                                            placeholder={81234567890}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3" style={{margin: '0 0 15px 0'}}>
                                <div className="body__form-item">
                                    <div className="form-item__title">Почта*</div>
                                    <div className="form-item__form">
                                        <input 
                                            className="form-item__select input-text"
                                            type="email"
                                            name="Email" 
                                            id="Email" 
                                            onChange={changeHandler}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="body__form-item col-xxl-3">
                                <div className="send-form">
                                    <button className="send-form__text black-button" type="submit" value="Отправить заявку" onClick={()=>validate()}>Отправить заявку</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body__agreement">
                        <div className="agreement__input">
                            <input 
                                id="checkbox"
                                className="custom-checkbox" 
                                value={isSubscribed}
                                onChange={handleCheckbox}
                                type="checkbox" 
                                required
                            />
                            <label htmlFor="checkbox" className="agreement__text">Согласен с&nbsp;<a style={{textDecoration: 'underline'}} href="#">обработкой персональных данных</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}