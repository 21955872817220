import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

export const RepairCont = () => {
    const [services, setServices] = useState([]);
    useEffect(()=>{
        fetch('/api/services')
        .then(res => res.json())
        .then(res => setServices(res))
    },[])
    return (
        <div className="container">
            <div id="crashes" className="crashes">
                <div className="crashes__title title">Распространенные поломки и их причины</div>
                <div className="crashes__subtitle subtitle">Осуществляем весь спектр услуг по обслуживанию, ремонту и модификации моноколес. Проведем диагностику, устраним неисправности</div>
                <div className="crashes__prices">
                    <div className="container">
                        <div className='row'>
                            {services.map((x, i, arr)=> {
                                if (((arr.length - 1) === i) && ((arr.length  % 2) === 1)) {
                                    return( 
                                        <div className="prices__item col-lg-4 col-md-12 row-flex" key={x.id}>
                                            <div className="prices__card-item card">
                                                <div className="card-body">
                                                    <h5 className="prices__title card-title">{x.title}</h5>
                                                    <p className="prices__main card-text">{x.description}</p>
                                                    <div className="prices__details">
                                                        <div className="details__time">≈ {x.time}</div>
                                                        <div className="details__price">{x.customPrice}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                }
                                return( 
                                <div className="prices__item col-lg-4 col-md-6 row-flex" key={x.id}>
                                    <div className="prices__card-item card">
                                        <div className="card-body">
                                            <h5 className="prices__title card-title">{x.title}</h5>
                                            <p className="prices__main card-text">{x.description}</p>
                                            <div className="prices__details">
                                                <div className="details__time">≈ {x.time}</div>
                                                <div className="details__price">{x.customPrice}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className="crashes__full-catalogue">
                            <a className="black-button" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} href="#">
                                <div className="full-catalogue__text full-catalogue-big">Полный каталог услуг</div>
                                <div className="full-catalogue__img full-catalogue-big__img"></div>
                            </a>
                        </div>
                    </div>
                </div>
                <a href="#request"><div className="crashes__fix-request yellow-button" style={{margin: '0'}}>Оставить заявку на ремонт</div></a>
            </div> 
        </div>
    )
}