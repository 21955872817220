import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Header } from './HeaderCont';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Footer } from './FooterCont';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className='container-fluid'>
          {this.props.children}
        <Footer />
      </div>
    );
  }
}

